<template>
  <v-card outlined>
    <v-sheet color="white">
      <v-row no-gutters class="pb-1 pt-2">
        <v-col cols="5" offset="2" :class="isMobile ? 'pr-1' : 'pr-2'">
          <v-container class="pa-0 pb-1 ma-0">
            <h3 class="tit font-weight-bold blue-grey--text text--darken-3 text-center"
                :style="isMobile ? 'font-size: 15px': 'font-size: 19px'"
            >
              Juny
            </h3>
          </v-container>
        </v-col>
        <v-col cols="5" :class="isMobile ? 'pl-1' : 'pl-2'">
          <v-container class="pa-0 pb-1 ma-0">
            <h3 class="tit font-weight-bold blue-grey--text text--darken-3 text-center pa-0"
                :style="isMobile ? 'font-size: 15px': 'font-size: 19px'"
            >
              Setembre
            </h3>

          </v-container>
        </v-col>
      </v-row>
    </v-sheet>
    <div>
      <v-row no-gutters>
        <v-col v-for="(value, i) in totsAnys"
               :key="i"
               :cols="value.mes==='Juny' ? 7 : 5"
        >
          <div v-if="value.any">

            <v-snackbar v-model="faltaPdf"
                        bottom
            >
              Aquest exàmen no està disponible.
              <v-btn
                color="amber lighten-2"
                text
                @click="faltaPdf = false"
              >
                Tancar
              </v-btn>
            </v-snackbar>
            <v-divider></v-divider>
            <v-card tile
                    elevation="0"
                    color="white"
            >
              <v-row no-gutters class="pt-2 pb-2 ">
                <v-col class="pa-0" :cols="value.mes === 'Setembre' ? 0 : 4">
                  <v-container class="pa-0 text-center pt-2"
                               v-if="value.mes !== 'Setembre'"
                  >
                    <h3 class="tit font-weight-bold blue-grey--text text--darken-3 body-1 ml-1"
                        style="'font-size: 12px'"
                        :class="isMobile ? 'pt-4' : 'mt-0'"
                    >
                      {{value.any}}
                    </h3>
                  </v-container>
                </v-col>
                <v-col :cols="value.mes === 'Setembre' ? 12 : 8"
                       class="pa-0">
                  <v-row no-gutters class="justify-center text-center">
                    <v-col :cols="isMobile ? 12 : 3"
                           class="pr-1 pa-0"
                    >
                      <v-btn :color="isEnSelected(value) ?'amber lighten-1' : '#256EFF'"
                             width="100%"
                             max-width="120"
                             dark
                             @click="getUrlPdf('Enunciat', value)"
                             depressed
                             text
                             style="letter-spacing: 0.7px !important; font-size: 16px"
                             class="text-none font-weight-medium pa-0 text-center"
                      >
                        Enunciat
                      </v-btn>
                    </v-col>
                    <v-col :cols="isMobile ? 12 : 3"
                           class="pa-0">
                      <v-btn :color="isSolSelected(value)  ?'amber lighten-1' : 'blue-grey darken-2'"
                             width="100%"
                             max-width="120"
                             dark
                             @click="getUrlPdf('Solucio', value)"
                             text
                             depressed
                             style="letter-spacing: 0.7px !important; font-size: 16px"
                             class="text-none font-weight-bold pa-0 text-center"
                      >
                        Solució
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
  export default {
    props: {
      subjectPath: String
    },
    data() {
      return {
        faltaPdf: false,
        selectedEn: false,
        selectedSol: false,
        selectedAny: null,
        selectedMes: null,
        getAnys: [2023, 2022, 2021, 2020, 2019, 2018, 2017, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000]
      }
    },
    computed: {
      isMobile: function () {
        return this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs'
      },
      totsAnys() {
        const array = []
        this.getAnys.forEach(any => {
          const juny = {
            any: any.toString(),
            mes: 'Juny'
          }
          const set = {
            any: any.toString(),
            mes: 'Setembre'
          }
          array.push(juny)
          array.push(set)
        })

        return array
      }
    },
    watch: {
      '$route.params.subject_name'() {
        this.selectedEn = false
        this.selectedSol = false
        this.selectedAny = null
        this.selectedMes = null
      }
    },
    methods: {
      isEnSelected(value) {
        return this.selectedEn && this.selectedAny === value.any && this.selectedMes === value.mes
      },
      isSolSelected(value) {
        return !this.selectedEn && this.selectedAny === value.any && this.selectedMes === value.mes
      },
      async getUrlPdf(locale, value) {
        const any = value.any
        const mes = value.mes
        if (this.$vuetify.breakpoint.smAndDown) {
          const pdf = `https://examenselectivitat.cat:3000/api/pdfs/${this.subjectPath}/${any}/${mes}/${locale}`;
          window.open(pdf);
        } else {
          this.$router.push({path: '/selectivitat/' + this.$route.params.subject_name + '/pdf/' + any + '/' + mes + '/' + locale})
        }
      }
    }
  }
</script>

<style scoped>

  .pressed {
    display: table-cell;
    position: relative;
    box-shadow: 0 0.14rem 0.25rem rgba(0, 0, 0, .16);
    width: 100vw;
    z-index: 100;
  }

  .tit {
    color: #333333;
    font-weight: 600;
    font-family: Rubik,sans-serif !important;
  }

</style>
