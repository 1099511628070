<template>
  <v-card
    @click="$router.push({path: `/dossiers-selectivitat`})"
    :ripple="false"
    :class="$vuetify.breakpoint.mdAndUp ? 'd-flex pa-6' : 'py-6'"
    style="border: 2px solid #E5E7EE"
    flat
    class="align-center rounded-lg">

    <div class="ribbon">
      <span class="text-rubik">Enviament 1-3 dies*</span>
    </div>


    <v-card
      style="max-height: 250px; max-width: 250px; transition: all 0.3s; transition-delay: 0.2s;"
      class="rounded-lg mb-2 mx-auto" flat tile>
      <v-img
        class="rounded-lg"
        style="max-height: 250px; max-width: 250px; transition: all 0.3s; transition-delay: 0.2s; min-height: 250px; min-width: 250px; background: #EFEFEF"
        :src="getUrl()"
      ></v-img>
    </v-card>

    <div class="d-flex flex-column pl-8">
      <div style="flex: 1">
            <span class="font-weight-bold font-rubik"
                  :style="$vuetify.breakpoint.mdAndUp ? 'font-size: 24px !important;' : 'font-size: 20px !important;'">
              Dossier {{ deApostrof(subjectName) }}</span>
        <p class="mb-0 mt-1 pr-4"
           :style="$vuetify.breakpoint.mdAndUp ? 'font-size: 16px !important;' : 'font-size: 14px !important;'"
        >
          Prepara't per la selectivitat fent un

          <span
            style="background-color: #C6EFFF; color: #4182FF"
            class="font-weight-medium pa-1 rounded">
                exercici cada dia</span>

          amb el dossier {{ deApostrof(subjectName) }}.
          Troba els darrers exàmens classificats
          <span
            style="background-color: #EAFBF4; color: #1B8F5D"
            class="font-weight-medium pa-1 rounded">
                per temes</span>
          i amb les
          <span
            style="background-color: #FFFAF0; color: #C05621"
            class="font-weight-medium pa-1 rounded">
                solucions al costat</span>
          per autocorregir-te.
        </p>
      </div>
      <v-btn
        v-if="subjectInfo"
        style="max-width: 300px; width: 100%"
        @click="$router.push({path: `/dossiers-selectivitat`})"
        class="text-none tit mt-4" depressed color="#DFE2E4">
        <span style="font-size: 15px; letter-spacing: 0">Més informació</span>
      </v-btn>

    </div>

  </v-card>
</template>

<script>

export default {
  name: "BookletCard",
  async mounted() {
    this.setCountdown()
    if (!this.sInfo)
      await this.fetchSubjectInfo()
    // this.subject = await Subject.build(this.subjectName, true);

  },
  props: {
    subjectName: {
      type: String,
      required: true
    },
    sInfo: Object
  },
  data() {
    return {
      subjectInfo: null,
      url: process.env.VUE_APP_AXIOS_URI,
      subject: null,
      loading: false,
      timer: {
        days: null,
        hours: null,
        minutes: null,
        seconds: null
      }
    }
  },
  methods: {
    getUrl() {
      const url = "https://examenselectivitat.cat:3000/api";
      return url + '/images/other/' + this.subjectName + '_examens_2024.webp'
    },
    getUrlHover() {
      const url = "https://examenselectivitat.cat:3000/api";
      return url + '/images/other/' + this.subjectName + '_hover.png'
    },
    async fetchSubjectInfo() {
      let path = `/subject-info/${this.subjectName}`

      await this.axios
        .get(path)
        .then(res => {
          this.subjectInfo = res.data
        })

    },
    deApostrof(assig) {
      const lletra = assig.charAt(0).toLowerCase()
      return lletra === 'a' || lletra === 'e' || lletra === 'i' || lletra === 'o' || lletra === 'u' || lletra === 'h' ? "d'" + assig : 'de ' + assig
    },
    setCountdown() {
      var finalDate = new Date(2022, 5, 22);
      var today = new Date();

      const timeLeft = finalDate.getTime() - today.getTime();

      this.timer.days = timeLeft / (1000 * 60 * 60 * 24)


    }
  },

}
</script>

<style scoped>
.temes-card {
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0 12px 24px rgb(2 30 84 / 10%) !important;
  min-height: 132px;
}


.ribbon {
  position: absolute;
  right: -6px;
  top: -7px;
  z-index: 1;
  overflow: hidden;
  width: 146px;
  height: 141px;
  text-align: center
}

.ribbon span {
  font-family: Rubik, sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  line-height: 30px;
  transform: rotate(
    45deg
  );
  width: 188px;
  display: block;
  background: #256EFF;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 30%);
  position: absolute;
  top: 43px;
  right: -36px;
}

.ribbon span:before {
  left: 0;
  border-left: 3px solid #256EFF;
  border-right: 3px solid transparent;
}

.ribbon span:after {
  right: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid #256EFF;
}

.ribbon span:after, .ribbon span:before {
  content: "";
  position: absolute;
  top: 100%;
  z-index: -1;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #256EFF;
}
</style>
